<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/TeacherIndex">
          <ion-icon :icon="homeOutline"/>
          <ion-label>首页</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/ClassRecord">
          <ion-icon :icon="timerOutline"/>
          <ion-label>销课记录</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/StudentList">
          <ion-icon :icon="peopleOutline"/>
          <ion-label>学员信息</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/TeacherPersonalIndex">
          <ion-icon :icon="personOutline"/>
          <ion-label>个人中心</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import {timerOutline, homeOutline, peopleOutline, personOutline} from "ionicons/icons";

export default {
  name: "TeacherTabs",
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage,IonRouterOutlet},
  setup() {
    return {
      timerOutline,
      homeOutline,
      peopleOutline,
      personOutline,
    };
  },

  mounted() {
    //
  },
};
</script>
